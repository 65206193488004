import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Link } from "shared/components/Link";
import ChevronLeft from "modules/reporting/assets/ChevronLeft.svg?react";
import { Select } from "shared/components/Select";
import { useFlags } from "launchdarkly-react-client-sdk";
import { usePermission } from "app/providers/PermissionsProvider";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportPeriodSelect } from "shared/components/ReportPeriodSelect";
import {
  reportPeriodCurrent,
  reportPeriodOptions,
} from "modules/reporting/lib/reportPeriodOptions";
import { useReportPageContext } from "modules/reporting/lib/ReportPageContext";

interface Option {
  value: string;
  label: string;
}

export const ReportPageTopBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const reportId = location.pathname.split("/")[2];
  const { reportPeriod, setReportPeriod } = useReportPageContext();
  const navigate = useNavigate();
  const options = useReportOptions();

  // Selected option on the report dropdown is controlled by the route
  const selected = options.find((o) => o.value === reportId);

  return (
    <Header>
      <Link type="button" to="/reporting" variant="secondary" icon={null}>
        <ChevronLeft />
      </Link>
      <span>{t("reporting.report")}:</span>
      <SelectWrapper>
        <Select
          name={t("reporting.report")}
          value={selected}
          setValue={(_, val: Option) => navigate(`/reporting/${val.value}`)}
          isSearchable={false}
          isClearable={false}
          options={options}
          placeholder={t("reporting.selectPlaceholder")}
        />
      </SelectWrapper>
      <PeriodSelectWrapper>
        <ReportPeriodSelect
          selected={reportPeriod}
          onChange={setReportPeriod}
          options={reportPeriodOptions}
          disabled={reportPeriod.value === reportPeriodCurrent.value}
        />
      </PeriodSelectWrapper>
    </Header>
  );
};

function useReportOptions() {
  const { t } = useTranslation();
  const {
    enableWoByAssigneeReportPage,
    enableMaintenanceHistoryByAssigneeReportPage,
    enableAssetCostsDowntimeReport,
    enableWoCompletedByCategoryReportPage,
    enablePartsInventoryReports,
  } = useFlags();
  const {
    reportsAssigneePermit,
    reportsPriorityPermit,
    reportsStatusPermit,
    reportsCostPermit,
    reportsOverduePermit,
  } = usePermission();

  return [
    {
      value: ReportKey.BacklogByStatus,
      label: t(`reporting.${ReportKey.BacklogByStatus}.title`),
      enabled: reportsStatusPermit,
    },
    {
      value: ReportKey.BacklogByPriority,
      label: t(`reporting.${ReportKey.BacklogByPriority}.title`),
      enabled: reportsPriorityPermit,
    },
    {
      value: ReportKey.CreatedVsCompleted,
      label: t(`reporting.${ReportKey.CreatedVsCompleted}.title`),
      enabled: true,
    },
    {
      value: ReportKey.ReactiveVsPreventive,
      label: t(`reporting.${ReportKey.ReactiveVsPreventive}.title`),
      enabled: true,
    },
    {
      value: ReportKey.OntimeVsOverdue,
      label: t(`reporting.${ReportKey.OntimeVsOverdue}.title`),
      enabled: reportsOverduePermit,
    },
    {
      value: ReportKey.MaintenanceHistoryByAssignee,
      label: t(`reporting.${ReportKey.MaintenanceHistoryByAssignee}.title`),
      enabled: enableMaintenanceHistoryByAssigneeReportPage,
    },
    {
      value: ReportKey.CompletedByCategory,
      label: t(`reporting.${ReportKey.CompletedByCategory}.title`),
      enabled: enableWoCompletedByCategoryReportPage,
    },
    {
      value: ReportKey.HistoryByCost,
      label: t(`reporting.${ReportKey.HistoryByCost}.title`),
      enabled: reportsCostPermit,
    },
    {
      value: ReportKey.AssetCostsAndDowntime,
      label: t(`reporting.${ReportKey.AssetCostsAndDowntime}.title`),
      enabled: enableAssetCostsDowntimeReport,
    },
    {
      value: ReportKey.PartsInventory,
      label: t(`reporting.${ReportKey.PartsInventory}.title`),
      enabled: enablePartsInventoryReports,
    },
    {
      value: ReportKey.BacklogByAssignee,
      label: t(`reporting.${ReportKey.BacklogByAssignee}.title`),
      enabled: enableWoByAssigneeReportPage && reportsAssigneePermit,
    },
  ].filter((o) => o.enabled);
}

const Header = styled.div`
  grid-area: header;

  position: sticky;
  background: var(--color_header_background);
  height: var(--header-height);
  top: 0;
  right: 0;
  margin: 0 40px;
  border-bottom: 1px solid var(--color_header_border);

  display: flex;
  align-items: center;
  gap: 12px;
  box-sizing: border-box;
  z-index: 1;
`;

const SelectWrapper = styled.div`
  width: 350px;
`;

const PeriodSelectWrapper = styled.div`
  width: 325px;
  margin-left: auto;
`;
