import React, { useRef, useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";

import RowIcon from "shared/assets/Row.svg?react";
import EntityIcon from "shared/assets/icon/menu/Entity.svg?react";
import { getUser, setUser } from "shared/components/Auth";

import { useGetEntitiesQuery } from "modules/entities";
import { useLazyGetUserPermissionsQuery } from "modules/users";

import { CMMSAPI } from "app/services";
import { usePermission } from "app/providers/PermissionsProvider";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetUserPermissionsQuery } from "modules/users/state/usersApi";
import { Tooltip } from "react-tooltip";

export const Entities = ({ hidden }) => {
  const [showEntities, setShowEntities] = useState(false);
  const currentUser = getUser();

  const [trigger] = useLazyGetUserPermissionsQuery();
  const { currentData } = useGetUserPermissionsQuery(
    currentUser?.id ?? skipToken
  );
  const { data: entities } = useGetEntitiesQuery();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const ref = useRef();

  const { setPermissions } = usePermission();

  const entityName = useMemo(() => {
    if (!entities) {
      return;
    }
    if (!currentUser.entity) {
      const [
        {
          id = "",
          name = "",
          datetime_format = "",
          timezone = "",
          currency = "",
        },
      ] = entities.data.length ? entities.data : [{}];

      setUser({
        ...currentUser,
        entity: id,
        currency,
        dateFormat: datetime_format,
        timezone,
      });
      return name;
    }

    const {
      name = "",
      datetime_format = "",
      currency = "",
      timezone = "",
      id = "",
    } = entities?.data.find((item) => item?.id === currentUser.entity) ||
    entities?.data[0] ||
    {};

    setUser({
      ...currentUser,
      currency,
      entity: id,
      dateFormat: datetime_format,
      timezone,
    });

    return name;
  }, [entities, currentUser]);

  const setEntity = ({ id }) => {
    if (id !== currentUser?.entity) {
      setUser({
        ...currentUser,
        entity: id,
      });

      setTimeout(() => {
        dispatch(CMMSAPI.util.invalidateTags([{ type: "Entity" }]));
        trigger(currentUser.id)
          .unwrap()
          .then(({ data }) => {
            const userData = {
              ...currentUser,
              permissions: data?.permissions,
              role: data?.role,
              entity: id,
            };
            setPermissions(data?.permissions);
            setUser(userData);
          });
      }, "300");
    }
  };

  useEffect(() => {
    if (
      currentData?.data?.role &&
      currentUser.role !== currentData?.data?.role
    ) {
      const userData = {
        ...currentUser,
        permissions: currentData?.data?.permissions,
        role: currentData?.data?.role,
      };
      setPermissions(currentData?.data?.permissions);
      setUser(userData);
    }
  }, [currentData?.data]);

  return (
    <EntityContainer>
      {entityName && (
        <Container
          active={showEntities}
          onClick={() => {
            setShowEntities(!showEntities);
          }}
          ref={ref}
        >
          <Wrapper active={showEntities} hidden={hidden}>
            {hidden ? (
              <>
                <EntityIconStyled
                  $active={showEntities}
                  data-tooltip-id={"entities-tooltip"}
                />
                <Tooltip
                  id={"entities-tooltip"}
                  content={"Entities"}
                  hidden={!hidden && !showEntities}
                  opacity={1.0}
                  place={"right"}
                  offset={26}
                  positionStrategy={"fixed"}
                />
              </>
            ) : (
              <>
                <EntitySelected>{entityName}</EntitySelected>
                <RowIcon style={{ flexShrink: 0 }} />
              </>
            )}
          </Wrapper>
          {showEntities &&
            createPortal(
              <EntitiesList hidden={hidden}>
                <Triangle />
                <EntitiesListHeader>{t("sidebar.entities")}</EntitiesListHeader>
                {entities?.data.map(({ id, name }) => (
                  <Entity key={id} onClick={() => setEntity({ id })}>
                    {name}
                  </Entity>
                ))}
              </EntitiesList>,
              document.body
            )}
        </Container>
      )}
    </EntityContainer>
  );
};

const EntityContainer = styled.div`
  min-height: 40px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  overflow: visible;
  background: ${({ active }) =>
    active ? "var(--color_corp_mint)" : "transparent"};
  &:hover {
    background: ${({ active }) =>
      active ? "var(--color_corp_mint)" : "var(--color_primary_hover)"};
  }
`;

const Wrapper = styled.div`
  padding: ${({ hidden }) => (hidden ? "0" : "0 20px")};
  color: ${({ active }) =>
    active ? "rgb(51, 50, 56)" : "var(--color_primary)"};
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: ${({ hidden }) => (hidden ? "center" : "space-between")};
`;

const EntityIconStyled = styled(EntityIcon)`
  stroke: ${({ $active }) =>
    $active ? "var(--color_dark_blue)" : "var(--color_primary)"};
`;

const EntitiesList = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 8px;
  left: ${({ hidden }) => (hidden ? 65 : 250)}px;
  z-index: 9999;
  top: ${({ hidden }) => (hidden ? 95 : 172)}px;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid rgba(191, 191, 195, 1);
  box-shadow: 0px 0px 2px 0px rgba(31, 30, 36, 0.16);
  gap: 2px;
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
  position: absolute;
  top: 10px;
  left: -8px;
`;
const Entity = styled.div`
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background: rgba(0, 240, 180, 1);
  }
  white-space: nowrap;
`;

const EntitiesListHeader = styled.div`
  font-size: 12px;
  font-weight: 600;
  padding: 0 12px 8px 12px;
`;

const EntitySelected = styled.div`
  font-size: 16px;
  font-weight: 600;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 6px;
`;
