import { NoData } from "modules/reporting/components/NoData";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatNumber } from "modules/reporting/lib/formatters";
import { MaintenanceHistoryByAssigneeChartData } from "./types";
import { BarChart } from "modules/reporting/components/charts/BarChart";
import { ChartColors } from "../../../../../shared/lib/colors";
import { ToggleButtonGroup } from "../../ToggleButtonGroup";
import styled from "styled-components";
export type MaintenanceByAssigneeChartMode =
  | "completed_work_orders"
  | "total_labor_hours";

interface MaintenanceHistoryByAssigneeReportChartProps {
  data?: MaintenanceHistoryByAssigneeChartData[] | [];
  isFetching: boolean;
  mode: string;
  setMode: (mode: string) => void;
}

export const MaintenanceHistoryByAssigneeReportChart = ({
  data,
  isFetching,
  mode,
  setMode,
}: MaintenanceHistoryByAssigneeReportChartProps) => {
  const { t, i18n } = useTranslation();
  if (!isFetching && !data?.length) {
    return <NoData />;
  }

  let bars = [];
  let displayData: MaintenanceHistoryByAssigneeChartData[] = [];
  const MAX_RECORD_COUNT = 10;
  const cloneData: MaintenanceHistoryByAssigneeChartData[] = data
    ? [...data]
    : [];
  const sortedWorkOrders = cloneData
    ?.sort((a, b) => b.completed_work_orders - a.completed_work_orders)
    .slice(0, MAX_RECORD_COUNT);
  const sortedMostHours = cloneData
    ?.sort((a, b) => b.total_labor_hours - a.total_labor_hours)
    .slice(0, MAX_RECORD_COUNT);
  const completedWOsBars = [
    {
      dataKey: "completed_work_orders",
      fill: ChartColors[0],
    },
  ];
  const workHoursBars = [
    {
      dataKey: "total_regular_labor_hours",
      fill: ChartColors[0],
      barSize: 20,
      name: t(
        `reporting.${ReportKey.MaintenanceHistoryByAssignee}.chart.total_regular_labor_hours`
      ),
    },
    {
      dataKey: "total_overtime_hours",
      barSize: 20,
      fill: ChartColors[1],
      name: t(
        `reporting.${ReportKey.MaintenanceHistoryByAssignee}.chart.total_overtime_hours`
      ),
    },
    {
      dataKey: "total_labor_hours",
      barSize: 20,
      fill: ChartColors[2],
      name: t(
        `reporting.${ReportKey.MaintenanceHistoryByAssignee}.chart.total_labor_hours`
      ),
    },
  ];
  if (mode === "completed_work_orders") {
    bars = completedWOsBars;
    displayData = sortedWorkOrders;
  } else {
    bars = workHoursBars;
    displayData = sortedMostHours;
  }
  return (
    <Wrapper>
      <ModeOptions
        value={mode}
        onChange={(val) => setMode(val as MaintenanceByAssigneeChartMode)}
        ariaLabel={t(
          `reporting.${ReportKey.MaintenanceHistoryByAssignee}.chart.mode`
        )}
        options={[
          {
            label: t(
              `reporting.${ReportKey.MaintenanceHistoryByAssignee}.chart.completed_work_orders`
            ),
            value: "completed_work_orders",
          },
          {
            label: t(
              `reporting.${ReportKey.MaintenanceHistoryByAssignee}.chart.total_labor_hours`
            ),
            value: "total_labor_hours",
          },
        ]}
      />
      <BarChart
        data={displayData}
        isLoading={isFetching}
        dataAxisKey="assignee"
        layout="vertical"
        valueFormatter={(val) => formatNumber(val, i18n.language)}
        yAxisProps={{ width: 140 }}
        xAxisProps={{ type: "number", domain: [0, "auto"] }}
        bars={bars}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const ModeOptions = styled(ToggleButtonGroup)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  padding-bottom: 8px;
`;
