import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "./Modal";
import { Button } from "shared/components/Button";
import {
  BodyWrapper,
  ActionBlock,
  ModalContentWrapper,
} from "./components/Modals.styled";
import { EditorHeader } from "./components/EditorHeader";
import { ScrollFadeBox } from "../ScrollFadeBox";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TooltipError = ({ field, errorType, t, i }) => (
  <TooltipNotification key={i}>
    {t(`validation.errors.fields.${field}`)}: {errorType}
  </TooltipNotification>
);

export const EditorModal = ({
  isOpen,
  label,
  onClose,
  onSubmit,
  disabledSubmit,
  isSubmitLoading,
  secondaryButtonLabel,
  primaryButtonLabel,
  children,
  form,
}) => {
  const { t } = useTranslation();
  const noChangesRow = (
    <TooltipRow>{t("validation.errors.no_changes")}</TooltipRow>
  );
  const [tooltipContent, setTooltipContent] = useState(noChangesRow);
  const { errors } = form || {};

  const renderErrors = useCallback(
    (errors) =>
      Object.entries(errors ?? {}).map(([field, errorType], i) => {
        if (typeof errorType === "object") {
          return renderErrors(errorType);
        }
        return <TooltipError errorType={errorType} field={field} t={t} i={i} />;
      }),
    [t]
  );
  const formatErrors = useMemo(() => {
    return (
      <TooltipWrapper>
        <span>{t(`validation.errors.errors_found`)}</span>
        {renderErrors(errors)}
      </TooltipWrapper>
    );
  }, [errors, renderErrors, t]);

  const onMouseHover = () => {
    if (form == null || !form.dirty) {
      setTooltipContent(noChangesRow);
    } else if (!form.isValid) {
      setTooltipContent(formatErrors);
    } else {
      setTooltipContent(null);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContentWrapper>
        <EditorHeader
          label={label ?? "Editor modal"}
          action={
            <ActionBlock>
              <Button onClick={onClose} variant="secondaryDark">
                {secondaryButtonLabel ?? "Cancel"}
              </Button>
              <Button
                onClick={onSubmit}
                disabled={disabledSubmit}
                isLoading={isSubmitLoading}
                onMouseOver={onMouseHover}
                variant="primaryDark"
                data-tooltip-id="tooltip-errors"
              >
                {primaryButtonLabel ?? "Save"}
                {tooltipContent && (
                  <ReactTooltip
                    id="tooltip-errors"
                    place="bottom-end"
                    content={tooltipContent}
                    opacity="1"
                  />
                )}
              </Button>
            </ActionBlock>
          }
        />
        <ScrollFadeBox disableTop>
          <BodyWrapper>{children}</BodyWrapper>
        </ScrollFadeBox>
      </ModalContentWrapper>
    </Modal>
  );
};

EditorModal.propTypes = {
  isOpen: PropTypes.bool,
  label: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  disabledSubmit: PropTypes.bool,
  secondaryButtonLabel: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
};

const TooltipRow = styled.div`
  color: var(--rt-color-white);
`;

const TooltipNotification = styled(TooltipRow)`
  color: var(--color_corp_red);
`;

const TooltipWrapper = styled.div`
  color: var(--color_corp_red);
`;
