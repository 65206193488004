import styled from "styled-components";
import { DonutChart } from "modules/reporting/components/charts/DonutChart";
import { useTranslation } from "react-i18next";
import { DonutChartWidgetLayout } from "modules/reporting/components/widgets/DonutChartWidgetLayout";
import { NoData } from "modules/reporting/components/NoData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { useReportPageContext } from "modules/reporting/lib/ReportPageContext";
import { SliceConfig } from "../../charts/DonutChart/DonutChart";
import { ChartColors } from "../../../../../shared/lib/colors";
import { formatCurrency, formatNumber } from "../../../lib/formatters";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";

interface WorkOrderCompletedByCategoryReportWidgetData {
  chart_data: {
    key: string;
    value: number;
    value2: number;
  }[];
  other_data: {
    mttr: number;
  };
}

const MAX_DISPLAYED_RECORDS = 8;

export const WorkOrderCompletedByCategoryReportWidget = () => {
  const { reportPeriod } = useReportPageContext();
  const { currency, language } = useLocaleSettings();
  const { t } = useTranslation();
  const { data, isFetching } =
    useWidgetData<WorkOrderCompletedByCategoryReportWidgetData>(
      ReportKey.CompletedByCategory,
      reportPeriod
    );

  const otherKey = t("reporting.completed_by_category.labels.other");
  const summarizeOtherData = (
    otherData: { key: string; value: number; value2: number }[]
  ): { key: string; value: number; value2: number } => {
    if (!otherData || otherData.length === 0) {
      return { key: otherKey, value: 0.0, value2: 0.0 };
    }
    const clonedData = [...otherData];
    return clonedData.reduce(
      (acc, curr) => {
        acc.value += parseInt(curr.value.toString());
        acc.value2 += parseFloat(curr.value2.toString());
        return acc;
      },
      { key: otherKey, value: 0.0, value2: 0.0 }
    );
  };

  const [displayData, otherData] = createDisplayData(data?.chart_data ?? []);
  displayData.push(summarizeOtherData(otherData));
  const sliceConfigs = useSliceConfigs(displayData);
  if (!data?.chart_data && !isFetching) {
    return <NoData />;
  }

  const defaultValue1 = displayData.reduce(
    (acc, curr) => acc + parseInt(curr.value.toString()),
    0
  );
  const defaultValue2 = displayData.reduce(
    (acc: number, curr) => acc + parseFloat(curr.value2.toString()),
    0
  );
  const defaults = {
    label: t(`reporting.${ReportKey.CompletedByCategory}.columns.completed`),
    value: formatNumber(defaultValue1, language),
    value2: formatCurrency(defaultValue2, language, currency),
  };

  return (
    <DonutChartWidgetLayout>
      <DonutChart.Root
        value2Formatter={(v) =>
          formatCurrency(parseFloat(v.toString()), language, currency)
        }
        data={displayData ?? []}
        isLoading={isFetching}
        sliceConfigs={sliceConfigs}
      >
        <LegendWrapper>
          <DonutChart.Legend />
        </LegendWrapper>
        <DonutChart.Chart>
          <DonutChart.Label defaultContents={defaults} />
        </DonutChart.Chart>
      </DonutChart.Root>
    </DonutChartWidgetLayout>
  );
};

function useSliceConfigs(
  displayData: { key: string; value: number; value2: number }[] | undefined
) {
  const colors = ChartColors;
  const slices: Record<string, SliceConfig> = {};
  if (!displayData) {
    return slices;
  }

  displayData.forEach(
    (item: { key: string; value: number; value2: number }, index): void => {
      slices[item.key] = {
        fill: colors[index % colors.length],
        label: item.key,
      };
    }
  );

  return slices;
}

const createDisplayData = (
  chart_data: { key: string; value: number; value2: number }[]
) => {
  const array = Array.from(chart_data);
  const display = array
    .sort((a, b) => b.value - a.value)
    .splice(0, MAX_DISPLAYED_RECORDS);
  return [display, array];
};

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;

  hr {
    width: 100%;
    border-style: solid;
    border-color: var(--color_grey100);
  }
`;
