import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { FileManager } from "modules/fileManager";
import { InlineLabel } from "shared/components/widgets";
import { Input } from "shared/components/Input";
import { Switch } from "shared/components/Switch";

import { useSearch } from "shared/lib/hooks/useSearch";

import { DateTimePicker } from "shared/components/DatePicker";
import { Select, SelectCreatable } from "shared/components/Select";
import { ChecklistManager } from "shared/components/Checklist";
import { PriorityInput } from "shared/components/Priority";

import { useGetAllAssetsQuery, AssetCreate } from "modules/assets";
import { useGetAllVendorsQuery, VendorCreate } from "modules/vendors";
import { useGetAllLocationsQuery, LocationCreate } from "modules/locations";
import { useGetCategoriesQuery } from "modules/categories";
// TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
// import { useGetTeamsQuery } from "modules/teams";
import { useGetTechniciansQuery } from "modules/users";

import { usePermission } from "app/providers/PermissionsProvider";

import {
  getOptions,
  getAssignToOptions,
  getTimezoneDateFormats,
} from "shared/lib/helpers";

import {
  getLocationsOptions,
  getAssetsOptions,
  getVendorsOptions,
  setAsset,
} from "shared/lib/helpers/data";
import { formatISO } from "date-fns";

export const RequestEditor = ({ type, form, optionalFields }) => {
  const { t } = useTranslation();
  const { dateTimePickerFormat } = getTimezoneDateFormats();

  const { locationCreatePermit, assetCreatePermit } = usePermission();

  const { location, asset, vendors: formVendors } = form.values;

  const [locations = [], onLocationsSearch] = useSearch(
    useGetAllLocationsQuery,
    optionalFields.hasOwnProperty("location_id"),
    undefined,
    "path_name"
  );

  const [categories = { data: [] }, onCategoriesSearch] = useSearch(
    useGetCategoriesQuery,
    optionalFields.hasOwnProperty("categories_ids")
  );

  const [assets = [], onAssetsSearch] = useSearch(
    useGetAllAssetsQuery,
    optionalFields.hasOwnProperty("asset_id")
  );

  const [vendors = [], onVendorsSearch] = useSearch(
    useGetAllVendorsQuery,
    optionalFields.hasOwnProperty("vendors_ids")
  );

  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const [teams, onTeamsSearch] = useSearch(
  //   useGetTeamsQuery,
  //   optionalFields.hasOwnProperty("assign_to")
  // );
  const [technicians, onTechniciansSearch] = useSearch(
    useGetTechniciansQuery,
    optionalFields.hasOwnProperty("assign_to"),
    {},
    "full_name"
  );
  // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
  // const assignToOptions = getAssignToOptions(teams, technicians);
  const assignToOptions = getAssignToOptions(technicians);

  const locationsOptions = getLocationsOptions(
    locations,
    {
      location,
      asset,
      vendors: formVendors,
    },
    "path"
  );

  const assetsOptions = getAssetsOptions(assets, {
    location,
    asset,
    vendors: formVendors,
  });

  const vendorsOptions = getVendorsOptions(vendors, {
    location,
    asset,
    vendors: formVendors,
  });

  const categoriesOptions = getOptions(categories);

  const onAssignToSearch = (value) => {
    // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
    // onTeamsSearch(value);
    onTechniciansSearch(value);
  };

  const assetStatus = useMemo(
    () =>
      assets?.find((asset) => asset.id === form.values.asset?.value)?.status,
    [assets, form.values.asset]
  );

  return (
    <>
      <WrapperFileManager
        name="uploads_attributes"
        value={form.values.uploads_attributes}
        setValue={form.handleSetValue}
      />
      <Input
        name="title"
        value={form.values.title}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        placeholder={t("work_order_requests.form.title")}
        errorMessage={form.touched.title && form.errors.title}
        height={50}
        fontSize={20}
        required
        aria-label="Title"
      />
      {optionalFields.hasOwnProperty("description") && (
        <InlineLabel
          label={t("work_order_requests.form.description")}
          labelWidth={110}
          isRequired={optionalFields.description.required}
        >
          <Input
            name="description"
            value={form.values.description}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            placeholder={t("work_order_requests.form.description")}
            errorMessage={form.touched.description && form.errors.description}
            isTextArea
            aria-label="Description"
          />
        </InlineLabel>
      )}
      {type !== "approve" && (
        <InlineLabel label="" labelWidth={110}>
          <Switch
            activated={form.values.notify}
            onClick={() => {
              const { handleSetValue } = form;
              handleSetValue("notify", !form.values.notify);
            }}
            dataTestId="receive-notifs-on-updates"
          >
            {t("work_order_requests.form.notify")}
          </Switch>
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("priority") && (
        <InlineLabel
          label={t("work_order_requests.form.priority")}
          labelWidth={110}
        >
          <PriorityInput
            value={form.values.priority}
            onChange={(value) => {
              const { handleSetValue } = form;
              handleSetValue("priority", value);
            }}
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("location_id") && (
        <InlineLabel
          label={t("work_order_requests.form.location")}
          labelWidth={110}
          isRequired={optionalFields.location_id.required}
        >
          {locationCreatePermit ? (
            <SelectCreatable
              options={locationsOptions}
              name="location"
              value={form.values.location}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              noOptionsMessage={t(
                "work_order_requests.form.depended.locations.noOptions"
              )}
              errorMessage={form.touched.location && form.errors.location}
              optionCreateModal={<LocationCreate />}
              onSearch={onLocationsSearch}
              onTouch={form.handleSetTouched}
              ariaLabel="Location"
            />
          ) : (
            <Select
              options={locationsOptions}
              name="location"
              value={form.values.location}
              setValue={form.handleSetValue}
              onBlur={form.handleBlur}
              noOptionsMessage={t(
                "work_order_requests.form.depended.locations.noOptions"
              )}
              errorMessage={form.touched.location && form.errors.location}
              onSearch={onLocationsSearch}
              onTouch={form.handleSetTouched}
            />
          )}
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("asset_id") && (
        <>
          <InlineLabel
            label={t("work_order_requests.form.assets")}
            labelWidth={110}
            isRequired={optionalFields.asset_id.required}
          >
            {assetCreatePermit ? (
              <SelectCreatable
                options={assetsOptions}
                name="asset"
                value={form.values.asset}
                setValue={(name, asset) =>
                  setAsset(name, asset, locations, assets, form)
                }
                onBlur={form.handleBlur}
                noOptionsMessage={t(
                  "work_order_requests.form.depended.assets.noOptions"
                )}
                errorMessage={form.touched.asset && form.errors.asset}
                optionCreateModal={<AssetCreate />}
                onSearch={onAssetsSearch}
                onTouch={form.handleSetTouched}
                ariaLabel="Asset"
              />
            ) : (
              <Select
                options={assetsOptions}
                name="asset"
                value={form.values.asset}
                setValue={(name, asset) =>
                  setAsset(name, asset, locations, assets, form)
                }
                onBlur={form.handleBlur}
                noOptionsMessage={t(
                  "work_order_requests.form.depended.assets.noOptions"
                )}
                errorMessage={form.touched.asset && form.errors.asset}
                onSearch={onAssetsSearch}
                onTouch={form.handleSetTouched}
              />
            )}
          </InlineLabel>
          {type === "approve" && assetStatus === "online" && (
            <InlineLabel label="" labelWidth={110}>
              <Switch
                activated={form.values.set_asset_to_offline}
                onClick={() => {
                  const { handleSetValue } = form;
                  handleSetValue(
                    "set_asset_to_offline",
                    !form.values.set_asset_to_offline
                  );
                }}
              >
                {t("work_order_requests.form.set_asset_to_offline")}
              </Switch>
            </InlineLabel>
          )}
        </>
      )}
      {optionalFields.hasOwnProperty("categories_ids") && (
        <InlineLabel
          label={t("work_order_requests.form.category")}
          labelWidth={110}
          isRequired={optionalFields.categories_ids.required}
        >
          <Select
            options={categoriesOptions}
            name="categories"
            value={form.values.categories}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={form.touched.categories && form.errors.categories}
            onSearch={onCategoriesSearch}
            onTouch={form.handleSetTouched}
            ariaLabel="Category"
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("due_date") && (
        <InlineLabel
          label={t("work_order_requests.form.due_date")}
          labelWidth={110}
          isRequired={optionalFields.due_date.required}
        >
          <DateTimePicker
            name="due_date"
            selected={form.values.due_date}
            onChange={(val) => form.handleSetValue("due_date", val)}
            dateFormat={dateTimePickerFormat}
            showIcon
            minDateTime={formatISO(new Date())}
            ariaLabelledBy="Needed by"
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("assign_to") && (
        <InlineLabel
          label={t("work_order_requests.form.assign_to")}
          labelWidth={110}
          isRequired={optionalFields.assign_to.required}
        >
          <Select
            options={assignToOptions}
            name="assign_to"
            value={form.values.assign_to}
            setValue={form.handleSetValue}
            isMulti
            onBlur={form.handleBlur}
            noOptionsMessage={t("noOptions")}
            errorMessage={form.touched.assign_to && form.errors.assign_to}
            onSearch={onAssignToSearch}
            onTouch={form.handleSetTouched}
            ariaLabel="Assign to"
          />
        </InlineLabel>
      )}
      {optionalFields.hasOwnProperty("vendors_ids") && (
        <InlineLabel
          label={t("work_order_requests.form.vendor")}
          labelWidth={110}
          isRequired={optionalFields.vendors_ids.required}
        >
          <SelectCreatable
            options={vendorsOptions}
            name="vendors"
            value={form.values.vendors}
            setValue={form.handleSetValue}
            isMulti
            menuPlacement="top"
            onBlur={form.handleBlur}
            noOptionsMessage={t(
              "work_order_requests.form.depended.vendors.noOptions"
            )}
            errorMessage={form.touched.vendors && form.errors.vendors}
            onSearch={onVendorsSearch}
            onTouch={form.handleSetTouched}
            ariaLabel="Vendors"
            optionCreateModal={<VendorCreate />}
          />
        </InlineLabel>
      )}
      {type === "approve" && optionalFields.hasOwnProperty("checklist") && (
        <InlineLabel
          label={t("work_orders.form.checklist")}
          labelWidth={110}
          isRequired={optionalFields.checklist.required}
        >
          <ChecklistManager
            name="checklist"
            value={form.values.checklist}
            onChange={form.handleChange}
            setValue={form.handleSetValue}
            onBlur={form.handleBlur}
            errorMessage={form.touched.checklist && form.errors.checklist}
            required={optionalFields.checklist.required}
          />
        </InlineLabel>
      )}
    </>
  );
};

const WrapperFileManager = styled(FileManager)`
  margin-bottom: 30px;
`;
