import React from "react";
import { NavLink as RoutLink } from "react-router-dom";
import styled from "styled-components";
import { SidebarItemLabel } from "widgets/SideBar/components/SidebarItemLabel";
import { Tooltip } from "react-tooltip";

export const NavLink = ({ href = "", icon, text, children, collapsed }) => {
  const Svg = icon;
  const dataTestId = text
    ? `${text.replaceAll(" ", "-").toLowerCase()}-nav`
    : undefined;
  return (
    <>
      <Link to={href} data-tooltip-id={text} data-testid={dataTestId}>
        {children ? (
          children
        ) : (
          <div>
            {icon && <Svg />}
            <SidebarItemLabel>{text}</SidebarItemLabel>
          </div>
        )}
      </Link>
      <Tooltip
        id={text}
        content={text}
        hidden={!collapsed}
        opacity={1.0}
        place={"right"}
        offset={15}
        positionStrategy={"fixed"}
      />
    </>
  );
};

const Link = styled(RoutLink)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 600;
  color: var(--color_text_light);
  & > div {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0px 20px;
  }
  & > div > svg {
    width: 20px;
    margin-right: 10px;
  }
  & > div > svg > path {
    stroke: var(--color_text_light);
  }

  &:hover {
    background: var(--color_primary_hover);
  }
  &.active {
    color: var(--color_text_color);
    & > div > svg > path {
      stroke: var(--color_text_color);
    }
    & > div > svg > circle {
      stroke: var(--color_text_color);
    }
    background: var(--color_primary);
    &:hover {
      background: var(--color_primary);
    }
  }
`;
