import React from "react";
import { useTranslation } from "react-i18next";
import { PageWrapper } from "shared/components/PageWrapper";
import { usePermission } from "app/providers/PermissionsProvider";
import {
  Title,
  ButtonsContainer,
  StyledLink,
  LinkContainer,
  AssetIconStyled,
  ModuleTitle,
  ModuleLabel,
  WrenchIconStyled,
  VendorIconStyled,
  PortalIconStyled,
} from "./AdministrationManageModule.styled";

export const AdministrationManageModule = () => {
  const { t } = useTranslation();
  const {
    adminSettingsAssetPermit,
    adminSettingsPartPermit,
    adminSettingsVendorPermit,
    adminSettingsWorkOrderRequestPermit,
    adminSettingsWorkOrderPermit,
    adminSettingsAssetTypesPermit,
    adminSettingsPartTypesPermit,
    adminSettingsCategoriesPermit,
    adminSettingsLocationsPermit,
    adminSettingsStoreroomsPermit,
  } = usePermission();

  const links = [
    {
      isShow: adminSettingsLocationsPermit,
      to: "/admin-settings/manage-modules/locations",
      icon: <AssetIconStyled />,
      title: t("locations.title"),
      label: t("settings.manageModules.locations"),
    },
    {
      isShow: adminSettingsAssetPermit,
      to: "/admin-settings/manage-modules/assets",
      icon: <AssetIconStyled />,
      title: t("assets.title"),
      label: t("settings.manageModules.assets"),
    },
    {
      isShow: adminSettingsVendorPermit,
      to: "/admin-settings/manage-modules/vendors",
      icon: <VendorIconStyled />,
      title: t("vendors.title"),
      label: t("settings.manageModules.vendors"),
    },
    {
      isShow: adminSettingsPartPermit,
      to: "/admin-settings/manage-modules/inventory-and-parts",
      icon: <WrenchIconStyled />,
      title: t("parts.title"),
      label: t("settings.manageModules.inventoryParts"),
    },
    {
      isShow: adminSettingsWorkOrderPermit,
      to: "/admin-settings/manage-modules/work-orders",
      icon: <WrenchIconStyled />,
      title: t("work_orders.title"),
      label: t("settings.manageModules.work_orders"),
    },
    {
      isShow: adminSettingsWorkOrderRequestPermit,
      to: "/admin-settings/manage-modules/requests",
      icon: <WrenchIconStyled />,
      title: t("work_order_requests.settingsTitle"),
      label: t("settings.manageModules.requests"),
    },
    {
      isShow: true,
      to: "/admin-settings/manage-modules/request-portals",
      icon: <PortalIconStyled />,
      title: t("request_portals.title"),
      label: t("settings.manageModules.request_portals"),
    },
  ];
  const referenceListsLinks = [
    {
      isShow: adminSettingsAssetTypesPermit,
      to: "/admin-settings/manage-modules/asset-types",
      icon: <AssetIconStyled />,
      title: t("settings.referenceLists.asset-types.title"),
      label: t("settings.referenceLists.asset-types.description"),
    },
    {
      isShow: adminSettingsCategoriesPermit,
      to: "/admin-settings/manage-modules/categories",
      icon: <VendorIconStyled />,
      title: t("settings.referenceLists.categories.title"),
      label: t("settings.referenceLists.categories.description"),
    },
    {
      isShow: adminSettingsStoreroomsPermit,
      to: "/admin-settings/manage-modules/storerooms",
      icon: <WrenchIconStyled />,
      title: t("settings.referenceLists.storerooms.title"),
      label: t("settings.referenceLists.storerooms.description"),
    },
    {
      isShow: adminSettingsPartTypesPermit,
      to: "/admin-settings/manage-modules/part-types",
      icon: <WrenchIconStyled />,
      title: t("settings.referenceLists.part-types.title"),
      label: t("settings.referenceLists.part-types.description"),
    },
  ];
  return (
    <PageWrapper>
      <Title>{t("settings.title")}</Title>
      <ButtonsContainer>
        {links.map(
          ({ to, title, label, icon, isShow }, i) =>
            isShow && (
              <StyledLink
                to={to}
                key={`${i}_${to}`}
                data-testid={`manage-${title
                  .replaceAll(" ", "-")
                  .toLowerCase()}`}
              >
                <LinkContainer>
                  <div>{icon}</div>
                  <div>
                    <ModuleTitle>{title}</ModuleTitle>
                    <ModuleLabel>{label}</ModuleLabel>
                  </div>
                </LinkContainer>
              </StyledLink>
            )
        )}
      </ButtonsContainer>
      <Title>{t("settings.referenceTitle")}</Title>
      <ButtonsContainer>
        {referenceListsLinks.map(
          ({ to, title, label, icon, isShow }, i) =>
            isShow && (
              <StyledLink
                to={to}
                key={`${i}_${to}`}
                data-testid={`manage-${title
                  .replaceAll(" ", "-")
                  .toLowerCase()}`}
              >
                <LinkContainer>
                  <div>{icon}</div>
                  <div>
                    <ModuleTitle>{title}</ModuleTitle>
                    <ModuleLabel>{label}</ModuleLabel>
                  </div>
                </LinkContainer>
              </StyledLink>
            )
        )}
      </ButtonsContainer>
    </PageWrapper>
  );
};
