import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NoData } from "modules/reporting/components/NoData";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { useReportPageContext } from "../../../lib/ReportPageContext";
import { formatNumber, formatPercent } from "../../../lib/formatters";
import { BarChart } from "../../charts/BarChart";
import { ChartColors } from "../../../../../shared/lib/colors";
import { TFunction } from "i18next";
import Skeleton from "react-loading-skeleton";
import { times } from "lodash";

interface MaintenanceHistoryByAssigneeReportWidgetData {
  chart_data: RowData[];
  other_data: {
    [key: string]: number;
  };
}

interface RowData {
  assignee: string;
  regular_labor_hours: number;
  overtime1_labor_hours: number;
  overtime2_labor_hours: number;
  total_hours: number;
}

export const MaintenanceHistoryByAssigneeReportWidget = () => {
  const { reportPeriod } = useReportPageContext();
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const MAX_DISPLAYED_RECORDS = 10;

  const { data, isFetching } =
    useWidgetData<MaintenanceHistoryByAssigneeReportWidgetData>(
      ReportKey.MaintenanceHistoryByAssignee,
      reportPeriod
    );

  if (isFetching) {
    return <WidgetSkeleton />;
  } else if (!data?.chart_data) {
    return <NoData />;
  }

  const clone_data = [...data?.chart_data];
  const display_items = clone_data
    .sort((a, b) => b.total_hours - a.total_hours)
    .slice(0, MAX_DISPLAYED_RECORDS);

  const kpis = data.other_data;
  const displayStat = (key: string, value: number | string) => {
    let str = value.toString();
    if (key.match(/percentage/)) {
      str = formatPercent(Number(value), language);
    }
    const kpi = t(
      `reporting.${ReportKey.MaintenanceHistoryByAssignee}.widget_kpis.${key}`,
      { value: str }
    );
    return <span>{kpi}</span>;
  };
  const displayedData = [
    "total_completed",
    "total_labor_hours",
    "overtime_labor_hours",
    "avg_time_to_complete",
    "overtime_labor_hours_percentage",
  ];
  const statBlocks = () => {
    return Object.entries(kpis)
      .filter((item) => displayedData.includes(item[0]))
      .map(([key, value]) => displayStat(key, value));
  };
  return (
    <Wrapper>
      <Stats>{statBlocks()}</Stats>
      <BarChart
        data={display_items}
        isLoading={isFetching}
        valueFormatter={(value) =>
          formatNumber(value, language, { maximumFractionDigits: 2 })
        }
        layout={"vertical"}
        dataAxisKey="assignee"
        chartMargin={{ top: 20 }}
        yAxisProps={{
          width: 150,
          minTickGap: 4,
        }}
        bars={barsConfig(display_items[0], t)}
      />
    </Wrapper>
  );
};
const barsConfig = (row: RowData, t: TFunction<"translation", undefined>) => {
  const barSize = 10;
  return [
    {
      dataKey: "total_labor_hours",
      barSize: barSize,
      barGap: 0,
      fill: ChartColors[1],
      name: t(
        `reporting.${ReportKey.MaintenanceHistoryByAssignee}.labels.total_labor_hours`
      ),
    },
    {
      dataKey: "total_overtime_hours",
      barSize: barSize,
      barGap: 0,
      fill: ChartColors[2],
      name: t(
        `reporting.${ReportKey.MaintenanceHistoryByAssignee}.labels.total_overtime_hours`
      ),
    },
    {
      dataKey: "total_regular_labor_hours",
      barSize: barSize,
      barGap: 0,
      fill: ChartColors[0],
      name: t(
        `reporting.${ReportKey.MaintenanceHistoryByAssignee}.labels.total_regular_labor_hours`
      ),
    },
  ];
};

const WidgetSkeleton = () => {
  return (
    <Wrapper>
      <Stats>
        <Skeleton height="1em" width="15ch" />
        <Skeleton height="1em" width="15ch" />
        <Skeleton height="1em" width="15ch" />
        <Skeleton height="1em" width="15ch" />
        <Skeleton height="1em" width="15ch" />
      </Stats>
      <BarChart
        data={times(5).map(() => ({
          assignee: "",
          regular_labor_hours: 0,
          overtime1_labor_hours: 0,
          overtime2_labor_hours: 0,
          total_hours: 0,
        }))}
        isLoading={true}
        layout={"vertical"}
        dataAxisKey="assignee"
        chartMargin={{ top: 20 }}
        yAxisProps={{
          width: 150,
          minTickGap: 2,
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-rows: 1fr auto auto;
  height: 100%;
`;

const Stats = styled.div`
  display: flex;
  gap: 24px;

  color: var(--color_dark_blue);
  font-size: 14px;
  font-weight: 600;
`;
