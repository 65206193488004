import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatCurrency } from "modules/reporting/lib/formatters";
import { PartsInventoryKPIs } from "./types";
import { ReportPeriod } from "modules/reporting/types/ReportPeriod";
import { useLocaleSettings } from "../../../lib/useLocaleSettings";

const reportKey = ReportKey.PartsInventory;
const MAX_DISPLAYED_KPIS = 8;
export const usePartsInventoryKPIs = (reportPeriod?: ReportPeriod) => {
  const { data, ...rest } = useGetKPIs<PartsInventoryKPIs>(
    reportKey,
    reportPeriod
  );
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const { currency } = useLocaleSettings();
  const results: { value: string; label: string }[] = [];
  const clone = data ? [...data] : [];
  clone
    .sort((a, b) => {
      return parseFloat(b.cost) - parseFloat(a.cost);
    })
    .slice(0, MAX_DISPLAYED_KPIS)
    .forEach((item) => {
      results.push({
        label: t(`reporting.${reportKey}.labels.spent`, { value: item.part }),
        value: formatCurrency(parseFloat(item.cost), language, currency),
      });
    });

  return {
    ...rest,
    data: results,
  };
};
