import { Header } from "widgets/Header";
import AddIcon from "shared/assets/icon/add.svg?react";
import { TablePageLayout } from "shared/components/TablePageLayout";
import { Container } from "shared/components/Container";
import { ModalLink } from "shared/components/ModalLink";
import { useTranslation } from "react-i18next";
import { List as ProceduresList } from "modules/procedures";

export const List = () => {
  const { t } = useTranslation();

  return (
    <TablePageLayout>
      <Header
        title={t("procedures.title")}
        searchPlaceholder={t("procedures.searchPlaceholder")}
        action={
          <ModalLink
            type="button"
            icon={<AddIcon />}
            modalParams={{ type: "wo", id: "new" }} // TODO: update to create Procedure
          >
            {t("procedures.title_singular")}
          </ModalLink>
        }
        horizontallySpaciousPage
      />
      <Container>
        <ProceduresList />
      </Container>
    </TablePageLayout>
  );
};
