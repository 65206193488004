import { CMMSAPI } from "app/services";

export const proceduresApi = CMMSAPI.injectEndpoints({
  endpoints: (builder) => ({
    getProcedureTemplates: builder.query({
      query: (params) => ({
        url: "/procedure_templates",
        params,
      }),
      providesTags: (result) => [
        { type: "Entity" },
        { type: "ProcedureTemplate", id: "ProcedureTemplatesList" },
      ],
    }),
  }),
});

export const { useGetProcedureTemplatesQuery } = proceduresApi;
