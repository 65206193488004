import { getUser } from "shared/components/Auth";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetUserQuery } from "modules/users/state/usersApi";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetCustomerQuery } from "modules/customer/state/customerApi";

/** Sets up the Intercom chat widget */
export const TotangoProvider = () => {
  const location = useLocation();
  const user = getUser() ?? {};

  const totango = window.totango;
  const totango_options = window.totango_options;

  const { data: userData } = useGetUserQuery(user?.id ?? skipToken);
  const { data: customerData } = useGetCustomerQuery(
    user?.customerId ?? skipToken
  );

  useEffect(() => {
    if (totango && customerData?.totango_id) {
      // See https://support.totango.com/hc/en-us/articles/204456359-System-and-core-attributes for possible attributes
      totango_options.user = {
        id: userData?.email,
        name: userData?.full_name,
        title: userData?.job_title,
        email: userData?.email,
        contact_account_role: userData?.permissions[0].role,
        contact_organization: userData?.tenant,
        location: location.pathname,
      };
      totango_options.location = location;
      totango_options.account = {
        id: customerData?.totango_id,
        name: customerData?.name,
      };
      totango.go(totango_options);
    }
  });
};
