import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  LabeledQRCodeStyled,
  LastUpdated,
  Row,
  Table,
  TopContainer,
} from "./Details.styled";
import { useDisplayDate } from "shared/lib/hooks";
import { H3 } from "shared/components/Typography";

// remove to share/widgets
export const Details = ({
  className,
  label,
  rows: rowsRaw,
  date,
  code,
  codeSize,
  isShowCodeText,
}) => {
  const displayDate = useDisplayDate();

  const rows = rowsRaw.map(({ label, value, multiline }) => (
    <Row multiline={multiline} key={label}>
      <span>{label}</span>
      <span
        data-testid={`view-modal-${label.replaceAll(" ", "-").toLowerCase()}`}
      >
        {value}
      </span>
    </Row>
  ));

  return (
    <div className={className}>
      <TopContainer data-testid="view-modal-details-heading">
        <H3>{label ?? "Details"}</H3>
        {date && (
          <LastUpdated>Last update on {displayDate(date, true)}</LastUpdated>
        )}
      </TopContainer>
      <Container>
        <Table>{rows}</Table>
        {code && (
          <LabeledQRCodeStyled
            data={code}
            isShowCodeText={isShowCodeText}
            size={codeSize}
          />
        )}
      </Container>
    </div>
  );
};

Details.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
      ]),
    })
  ),
  code: PropTypes.string,
};
