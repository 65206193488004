import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { skipToken } from "@reduxjs/toolkit/query";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { HeaderContextMenu, ViewModal } from "shared/components/Modals";
import { Button } from "shared/components/Button";
import ThinAddIcon from "shared/assets/icon/thin-add.svg?react";
import { Switch } from "shared/components/Switch";
import {
  useDeleteAssetMutation,
  useGetAssetQuery,
  useGetAssetsQuery,
  useDeleteAssetsMutation,
  useGetAssetHierarchicalListQuery,
  AssetCreate,
} from "modules/assets";
import { AssetStatusBadge } from "modules/assets/components/AssetStatusBadge";
import { useGetWorkOrdersQuery } from "modules/workOrders";
import { FileManager } from "modules/fileManager";
import {
  StatusDetails,
  useGetDowntimeTrackingSettingsQuery,
} from "modules/downtimeTracking";
import { WorkOrderCreate } from "modules/workOrders";
import { ContextMenuEntry } from "shared/components/ContextMenu";
import { Deletion } from "features/Deletion";
import { Pagination } from "features/Pagination";
import { useVisibleFields } from "modules/adminFieldsConfiguration/lib/hooks";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { View } from "./View";
import { Divider } from "shared/components/Divider";
import { getModalPath, useSetModal } from "widgets/ModalController";
import { WOList, WOListChart } from "shared/components/WOList";
import { transformWOtoEvents } from "shared/lib/helpers/transformWOtoEvents";
import { H3 } from "shared/components/Typography";
import { RESPONSE_MESSAGE } from "shared/lib/constants/api";
import { HierarchicalList } from "shared/components/HierarchicalList";
import {
  useDefaultTimePeriod,
  useSearch,
  useTimePeriodOptions,
} from "shared/lib/hooks";
import { usePermission } from "app/providers/PermissionsProvider";
import { Tabs } from "./Tabs";
import { useLocation } from "react-router-dom";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";
import { PeriodSelect } from "shared/components/PeriodSelect/index.js";

export const AssetView = ({ isShow, assetId, onClose, onEdit, onClone }) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const location = useLocation();
  const reportPeriodOptions = useTimePeriodOptions();

  const defaultTimePeriod = useDefaultTimePeriod();

  const setModal = useSetModal();
  const [tabIndex, setTabIndex] = useState(0);
  const [hideCompletedWO, setHideCompletedWO] = useState(false);
  const [currentHistoryTimePeriod, setCurrentHistoryTimePeriod] =
    usePersistentState("asset_chart_period", defaultTimePeriod);

  const [deleteAsset] = useDeleteAssetMutation();
  const [deleteAssets] = useDeleteAssetsMutation();

  const {
    assetsViewAssociationsPermit,
    assetsViewAssociationsChartPermit,
    assetUpdatePermit,
    assetDeletePermit,
    assetClonePermit,
    woCreatePermit,
    assetsViewSubRecordsPermit,
    assetsAddSubRecordsPermit,
  } = usePermission();

  const { areVisibleFieldsLoading, isFieldVisible } = useVisibleFields("asset");

  const {
    currentData: assetData,
    error,
    refetch: refetchDetails,
  } = useGetAssetQuery(assetId ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: hierarchyTree = { tree: [] },
    error: hierarchyTreeError,
    refetch: refetchTree,
  } = useGetAssetHierarchicalListQuery(assetId ? assetId : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [assets = { data: [] }, onAssetsSearch] = useSearch(
    useGetAssetsQuery,
    true,
    //assets from the asset family
    {
      "filter[g][0][ancestry_matches]": `/${assetData?.root_id}/%25`,
      "filter[g][0][id_eq]": assetData?.root_id,
      "filter[g][0][m]": "or",
    },
    undefined,
    { refetchOnMountOrArgChange: true }
  );

  const [paginationData, setPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [workOrderModal, setWorkOrderModal] = useState(null);
  const [addChildModal, setAddChildModal] = useState(false);

  const { data: WOData } = useGetWorkOrdersQuery(
    {
      page: currentPage,
      per_page: 3,
      format_resource: "light",
      filter: {
        asset_id_eq: assetData?.id,
        created_at_gt: currentHistoryTimePeriod?.startDate,
        created_at_lt: currentHistoryTimePeriod?.endDate,
        ...(hideCompletedWO ? { work_order_status_id_not_eq: 5 } : {}),
      },
    },
    { skip: !assetId || !assetsViewAssociationsPermit }
  );

  const transformedWOData = transformWOtoEvents(WOData);

  const { data: downtimeTrackingSettings } =
    useGetDowntimeTrackingSettingsQuery({
      targetType: "asset",
    });
  const downtimeTrackingEnabled = downtimeTrackingSettings?.enabled || false;

  // TODO: Add a proper 404
  useEffect(() => {
    if (error && error.status === 404) {
      onClose();
    }
  }, [error, onClose]);

  useEffect(() => {
    if (WOData) {
      setPaginationData(WOData.meta.pagination);
    }
  }, [WOData]);

  useEffect(() => {
    setTabIndex(0);
  }, [isShow]);

  if (areVisibleFieldsLoading || !assetData) return null;

  const refetchData = () => {
    refetchDetails();
    refetchTree();
  };

  const deleteErrorHandler = (message) => {
    const content =
      message.base?.[0] === RESPONSE_MESSAGE.connected_record
        ? t("assets.warningDeletionContent")
        : JSON.stringify(message);

    addSnackBar({
      title: t("assets.warningDeletionTitle"),
      content,
      type: "warning",
      secondaryButtonLabel: t("close"),
    });
  };

  const deleteBulkErrorHandler = (error) => {
    const names = [];
    error.forEach((error) => {
      names.push(error?.body?.record?.name || "");
    });
    addSnackBar({
      title: t("assets.bulkActions.delete.subWarning.warningTitle"),
      content: t("assets.bulkActions.delete.subWarning.warningContent", {
        names: names.join(", "),
      }),
      type: "warning",
      secondaryButtonLabel: t("close"),
    });
    handleOnClose();
  };

  const deletingBulkSuccessHandler = (count) => {
    handleOnClose();
    addSnackBar({
      content: t("assets.bulkActions.delete.success", { count }),
      type: "success",
    });
  };

  const onAssociationError = () => {
    addSnackBar({
      title: t("assets.warningDeletionTitle"),
      content: t("assets.warningDeletionContent"),
      type: "warning",
      secondaryButtonLabel: t("close"),
    });
  };

  const handleOnClose = () => {
    onClose();
    setCurrentPage(1);
    setHideCompletedWO(false);
  };

  const handleCreateWO = () => {
    let defaultValues = { asset: { value: assetId, label: assetData.name } };
    if (assetData.location) {
      defaultValues.location = {
        value: assetData.location.id,
        label: assetData.location.name,
      };
    }
    setWorkOrderModal(defaultValues);
  };

  const showWOChart =
    assetsViewAssociationsChartPermit && assetData?.work_orders?.length > 0;
  const showWOList =
    assetsViewAssociationsPermit &&
    (transformedWOData.data.length > 0 || hideCompletedWO);
  return (
    <ViewModal
      label={
        assetData?.name
          ? `${t("assets.asset_one")} | ${assetData.name}`
          : t("assets.asset_one")
      }
      isOpen={isShow}
      onClose={handleOnClose}
      subHeader={
        <>
          <Tabs
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            currentStatus={assetData.status}
            downtimeTrackingEnabled={downtimeTrackingEnabled}
          />
          {woCreatePermit && (
            <Button onClick={handleCreateWO}>
              <WorkOrderButton>
                <ThinAddIcon />
                <span> {t("workOrder.title")}</span>
              </WorkOrderButton>
            </Button>
          )}
          <MarginedButton
            variant="secondary"
            onClick={() => onEdit(assetId)}
            disabled={!assetUpdatePermit}
          >
            {t("edit")}
          </MarginedButton>
          <HeaderContextMenu disabled={!assetClonePermit && !assetDeletePermit}>
            <>
              {assetClonePermit && (
                <ContextMenuEntry onClick={() => onClone(assetId)}>
                  {t("clone")}
                </ContextMenuEntry>
              )}
              {assetDeletePermit && (
                <Deletion
                  id={assetId}
                  module={assetData?.name}
                  onSuccess={handleOnClose}
                  onBulkSuccess={deletingBulkSuccessHandler}
                  onError={deleteErrorHandler}
                  onBulkError={deleteBulkErrorHandler}
                  onAssociationError={onAssociationError}
                  confirmation={{
                    title: t("assets.deletionConfirmation.title"),
                    description: t("assets.deletionConfirmation.description"),
                    confirmButtonLabel: t(
                      "assets.deletionConfirmation.confirm"
                    ),
                  }}
                  subConfirmation={{
                    title: t("assets.subDeletionConfirmation.title"),
                    description: t(
                      "assets.subDeletionConfirmation.description"
                    ),
                    confirmButtonLabel: t(
                      "assets.subDeletionConfirmation.confirm"
                    ),
                    cancelButtonLabel: t(
                      "assets.subDeletionConfirmation.cancel"
                    ),
                  }}
                  onDelete={deleteAsset}
                  onBulkDelete={deleteAssets}
                  hierarchyTree={hierarchyTree}
                  hasChildren={assetData.has_children}
                  hasAssociation={
                    assetData?.work_order_requests?.length > 0 ||
                    assetData?.work_orders?.length > 0
                  }
                />
              )}
            </>
          </HeaderContextMenu>
        </>
      }
    >
      {tabIndex === 0 && (
        <>
          {assetData && (
            <View
              id={assetData.id}
              name={assetData.name}
              description={assetData.description}
              uploadsAttributes={[
                ...assetData.images.map((file) => ({ ...file, isImage: true })),
                ...assetData.documents,
              ]}
              updatedAt={assetData.updated_at}
              location={assetData.location}
              // TODO: Commented due to {https://jira.teleport.sumatosoft.work/jira/browse/CMMS-1481}, will be returned in R3/R4
              // teams={assetData.teams}
              technicians={assetData.technicians}
              model={assetData.model}
              assetTypes={assetData.asset_types}
              manufacturer={assetData.manufacturer}
              serialNumber={assetData.serial_number}
              purchaseDate={assetData.purchase_date}
              parent={assetData.parent}
              urls={assetData.urls}
              vendors={assetData.vendors}
              parts={assetData.parts}
              warrantyExpirationDate={assetData.warranty_exp_date}
              warrantyExpirationNotification={
                assetData.warranty_expiration_notification
              }
              lifeExpectancy={assetData.life_expectancy}
              purchaseCost={assetData.purchase_cost}
              replacementCost={assetData.replacement_cost}
              salvageValue={assetData.salvage_value}
              code={assetData.qr_code}
              FilesNode={FileManager}
              isFieldVisible={isFieldVisible}
            />
          )}
          {assetsViewSubRecordsPermit && (
            <>
              <DividerStyled />
              <HierarchicalList
                data={hierarchyTree}
                error={hierarchyTreeError}
                module="assets"
                onClickAdd={() => setAddChildModal(true)}
                addPermission={assetsAddSubRecordsPermit}
                getItemLink={(id) => getModalPath(location, "asset", id)}
                renderListItemRight={(item) =>
                  downtimeTrackingEnabled ? (
                    <AssetStatusBadge status={item.status} />
                  ) : undefined
                }
              />
            </>
          )}
          {addChildModal && (
            <AssetCreate
              isShow={true}
              onClose={() => setAddChildModal(false)}
              defaultValues={{
                location: assetData?.location
                  ? {
                      value: assetData.location.id,
                      label: assetData.location.name,
                    }
                  : null,
                parent: { value: assetData.id, label: assetData.name },
              }}
              defaultAssets={assets}
              defaultOnAssetsSearch={onAssetsSearch}
              onCreate={refetchData}
            />
          )}
          <DividerStyled />
          {(showWOChart || showWOList) && (
            <Title>
              <H3>{t("work_orders.history")}</H3>
              <PeriodSelect
                value={currentHistoryTimePeriod}
                onChange={setCurrentHistoryTimePeriod}
                options={reportPeriodOptions}
              />
            </Title>
          )}
          {showWOChart && (
            <WOListChart
              id={assetData.id}
              currentTimePeriod={currentHistoryTimePeriod}
              attribute="asset_id_eq"
            />
          )}
          {showWOList && (
            <>
              <StyledSwitch
                onClick={() => setHideCompletedWO((prev) => !prev)}
                activated={hideCompletedWO}
              >
                {t(`work_orders.hideWorkOrders`)}
              </StyledSwitch>
              <WOList
                events={transformedWOData.data}
                onOpenWorkOrder={(id) => setModal({ type: "wo", id })}
                calendar={false}
                withOverdue
              />
              <DividerStyled />
              <Pagination
                paginationData={paginationData}
                label={t("work_orders.title_abb")}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </>
          )}
        </>
      )}
      {tabIndex === 1 && (
        <StatusDetails
          currentStatus={assetData.status}
          id={assetData.id}
          name={assetData.name}
          assetCreationDate={assetData.created_at}
          workOrders={assetData.work_orders}
        />
      )}
      {workOrderModal && (
        <WorkOrderCreate
          isShow={Boolean(workOrderModal)}
          defaultValues={workOrderModal}
          onClose={() => setWorkOrderModal(null)}
          onOpen={setModal}
        />
      )}
    </ViewModal>
  );
};

AssetView.propTypes = {
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
};

const StyledSwitch = styled(Switch)`
  justify-content: end;
  margin: 8px 0;
  line-height: 20px;

  & span {
    font-weight: 400;
  }
`;

const MarginedButton = styled(Button)`
  margin-left: auto;
`;

const DividerStyled = styled(Divider)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const WorkOrderButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;
