import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSortParam } from "shared/lib/hooks";
import { Sort } from "./Sort";
import { CellCheckbox, HeaderRow, HeadTextContainer, Th } from "./styled";

export const Head = ({
  columns,
  rows = [],
  selectionModel,
  onSelectionModelChange,
  entityTranslationKey,
  bulkActionsNode,
}) => {
  const { t } = useTranslation();
  const [sort, setSort] = useSortParam();

  const { rowsIds, checked, partially, quantity } = useMemo(() => {
    const rowsIds = rows.map(({ id }) => id);

    const matchingIds = selectionModel
      ? rowsIds.filter((id) => selectionModel.includes(id))
      : null;

    return {
      rowsIds,
      checked: matchingIds && matchingIds.length > 0,
      partially: matchingIds && matchingIds.length !== rowsIds.length,
      quantity: matchingIds ? matchingIds.length : 0,
    };
  }, [rows, selectionModel]);

  const checkboxClickHandler = () => {
    if (onSelectionModelChange) {
      onSelectionModelChange(selectionModel.length === 0 ? rowsIds : []);
    }
  };

  const sortModel = useMemo(() => {
    const [field, dir] = (sort ?? "").split("+");
    return { [field]: dir };
  }, [sort]);

  const head = columns.map(
    (
      { field, sortQueryParam, initialSort, type, name, sortable, notPrimary },
      index
    ) => {
      const isFirstColumn = index === 0;
      const sortParamName = sortQueryParam ?? field;

      return (
        <Th
          notPrimary={notPrimary}
          key={field}
          scope="col"
          type={type}
          index={index}
          data-testid={`${field}-column-header`}
        >
          {selectionModel && isFirstColumn && (
            <CellCheckbox
              checked={checked}
              partially={partially}
              onClick={checkboxClickHandler}
            />
          )}
          <HeadTextContainer>
            {isFirstColumn && entityTranslationKey && checked
              ? `${quantity} ${t(entityTranslationKey, { count: quantity })}`
              : name}
          </HeadTextContainer>
          <Sort
            field={sortParamName}
            sortable={sortable}
            sort={sortModel[sortParamName]}
            onSortModelChange={setSort}
            initialSort={initialSort}
          />
          {isFirstColumn && bulkActionsNode && checked && (
            <BulkActionsBox>{bulkActionsNode}</BulkActionsBox>
          )}
        </Th>
      );
    }
  );

  return <HeaderRow>{head}</HeaderRow>;
};

const BulkActionsBox = styled.div`
  margin-left: auto;
  display: flex;
  gap: 10px;
`;
