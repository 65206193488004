import React from "react";
import PropTypes from "prop-types";
import {
  ArrowContainer,
  HeaderContainer,
  Label,
  SubHeader,
  TopHeader,
} from "./Modals.styled";

export const ModalHeader = ({
  label,
  action,
  subHeader,
  variant = "dark",
  flipAction = undefined,
}) => {
  return (
    <HeaderContainer variant={variant}>
      <TopHeader variant={variant}>
        {flipAction ? (
          <ArrowContainer>
            {flipAction}
            <Label variant={variant}>{label}</Label>
          </ArrowContainer>
        ) : (
          <Label variant={variant} data-testid="view-modal-heading">
            {label}
          </Label>
        )}
        {action}
      </TopHeader>
      {subHeader && <SubHeader variant={variant}>{subHeader}</SubHeader>}
    </HeaderContainer>
  );
};

ModalHeader.propTypes = {
  label: PropTypes.string,
  action: PropTypes.element,
  subHeader: PropTypes.element,
  variant: PropTypes.oneOf(["light", "dark"]),
};
