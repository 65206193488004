import { useEffect, useMemo } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";

export const useForm = ({
  initialValues,
  willUpdatedValues,
  validationSchema,
  onSubmit,
  validateOnChange = true,
  enableReinitialize = true,
}) => {
  const { setValues, ...form } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize,
    validateOnChange,
  });

  useEffect(() => {
    willUpdatedValues && setValues(willUpdatedValues);
  }, [willUpdatedValues, setValues]);

  const isWasChanged = useMemo(
    () => JSON.stringify(willUpdatedValues) !== JSON.stringify(form.values),
    [willUpdatedValues, form.values]
  );

  form.handleSetValue = (name, value, touched = true) => {
    return form.setFieldValue(name, value, false).then(() => {
      return form.setFieldTouched(name, touched, true);
    });
  };

  form.handleSetTouched = (name) => form.setFieldTouched(name, true, false);

  form.isFormValid =
    Object.keys(form.errors).length === 0 &&
    Object.keys(form.touched).length !== 0;

  form.isWasChanged = isWasChanged;
  return form;
};

export const propTypesFormik = PropTypes.shape({
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSetValue: PropTypes.func,
  handleSetTouched: PropTypes.func,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isFormValid: PropTypes.bool,
});

export const defaultPropsFormik = {
  values: {},
  errors: {},
  touched: {},
  handleSubmit: () => null,
  handleChange: () => null,
  handleBlur: () => null,
  handleSetValue: () => null,
  handleSetTouched: () => null,
};
