import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";

import { Details } from "widgets/Details";

import { useUpdateAssetMutation } from "modules/assets";
import {
  getViewRows,
  useGetWorkOrderQuery,
  useGetWorkOrdersHistoriesQuery,
} from "modules/workOrders";
import { useVisibleFields } from "modules/adminFieldsConfiguration";
import {
  getTrackingFormatForService,
  updateStatusValidationSchema,
  useGetDowntimeTrackingSettingsQuery,
} from "modules/downtimeTracking";
import { FileManager } from "modules/fileManager";
import {
  useAddWorkOrderTimeMutation,
  WorkOrderTimeList,
  useUpdateWorkOrderTimeMutation,
  useDeleteWorkOrderTimeMutation,
  WorkOrderTimeStop,
  useStartWorkOrderTimerMutation,
  useStopWorkOrderTimerMutation,
  useTimer,
  TimerIcon,
} from "modules/workOrderTime";
import {
  useUpdateTransactionMutation,
  useDeleteTransactionMutation,
} from "modules/parts";
import { WorkOrderPartList } from "modules/workOrderParts";
import {
  WorkOrderOtherCostsList,
  useAddWorkOrderOtherCostMutation,
  getOtherCostFormatForService,
  useGetWorkOrderOtherCostsQuery,
  useUpdateWorkOrderOtherCostMutation,
  useDeleteWorkOrderOtherCostMutation,
} from "modules/workOrderOtherCosts";

import { Button } from "shared/components/Button";
import { ViewModal } from "shared/components/Modals";
import { Description, Hint } from "shared/components/Typography";
import { useDisplayDate } from "shared/lib/hooks";
import { useForm } from "shared/lib/hooks/useForm";
import { Priority } from "shared/components/Priority";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import { getUser } from "shared/components/Auth";

import ThinAddIcon from "shared/assets/icon/thin-add.svg?react";
import Time from "../../assets/icons/time.svg?react";
import { getDueDate, getOverdueDate } from "../../lib/helpers";
import { WorkOrderHeader } from "./WorkOrderHeader";
import { WOViewTabs } from "./WOViewTabs";
import {
  ViewHeader,
  WorkOrderInfo,
  Name,
  Overdue,
  DividerStyled,
  HeaderDividerStyled,
  CostAndTimeContainer,
  Timer,
  StopTimerButton,
  DueDate,
  Fanfare,
} from "./WorkOrderView.styled";
import { WorkOrderChecklist } from "./WorkOrderChecklist";
import {
  useUpdateWorkOrderMutation,
  useAddPartTransactionMutation,
} from "modules/workOrders/state/workOrdersApi";
import { WorkOrderTotals } from "./WorkOrderTotals";
import { usePermission } from "app/providers/PermissionsProvider";
import { isWorkOrderOverdue } from "modules/workOrders/lib/helpers/isWorkOrderOverdue";
import { DowntimeDialog } from "modules/downtimeTracking/components/DowntimeDialog";

const fields = [
  "due_date",
  "type",
  "categories",
  "asset",
  "downtime_tracking",
  "location",
  "vendors",
  "assign_to",
  "schedule",
  "request",
  "requested_by",
  "created_by",
];

export const WorkOrderView = ({ isShow, onClose, onEdit, workOrderId }) => {
  const woId = workOrderId;
  const { t } = useTranslation();
  const displayDate = useDisplayDate();

  const { addSnackBar } = useSnackBar();

  const fanfareRef = useRef(null);

  const [addTime] = useAddWorkOrderTimeMutation();
  const [updateTime] = useUpdateWorkOrderTimeMutation();
  const [deleteTime] = useDeleteWorkOrderTimeMutation();
  const [startTimer] = useStartWorkOrderTimerMutation();
  const [stopTimer] = useStopWorkOrderTimerMutation();
  const [updateWorkOrder] = useUpdateWorkOrderMutation();
  const [addPartTransaction] = useAddPartTransactionMutation();
  const [updatePartTransaction] = useUpdateTransactionMutation();
  const [deletePartTransaction] = useDeleteTransactionMutation();
  const [addOtherCost] = useAddWorkOrderOtherCostMutation();
  const [updateOtherCost] = useUpdateWorkOrderOtherCostMutation();
  const [deleteOtherCost] = useDeleteWorkOrderOtherCostMutation();
  const [updateAsset] = useUpdateAssetMutation();

  const [isShowStopForm, setIsShowStopForm] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [manualStatus, setManualStatus] = useState(null);
  const [checklistValue, setChecklistValue] = useState(null);

  const currentUserId = getUser()?.id;

  const {
    isUserActiveTimer,
    isWOActiveTimer,
    timer,
    isTimesOverdue,
    setTimerRunning,
    timesValue,
  } = useTimer(woId);

  const {
    assetsStatusesAddDowntimePermit,
    woViewChecklistPermit,
    canUpdateWOChecklist,
    woPartsViewPermit,
    canAddPartWO,
    canUpdatePartWO,
    canDeletePartWO,
    canViewTimeWO,
    canAddTimeWO,

    canStartTimerWO,
    canDeleteTimeWO,
    canAddOtherCostWo,
    canEditOtherCostWo,
    canDeleteOtherCostWo,
    woOtherCostViewPermit,
  } = usePermission();

  const { currentData: data, error } = useGetWorkOrderQuery(woId ?? skipToken);

  const { currentData: histories } = useGetWorkOrdersHistoriesQuery(
    woId ?? skipToken
  );

  const { currentData: otherCosts } = useGetWorkOrderOtherCostsQuery(
    {
      "filter[work_order_id_eq]": woId,
    },
    { skip: !woId }
  );

  const { data: downtimeTrackingSettings } =
    useGetDowntimeTrackingSettingsQuery({
      targetType: "asset",
    });
  const downtimeTrackingEnabled = downtimeTrackingSettings?.enabled ?? false;

  const isManualType = !!manualStatus;
  const endedAtRequired = data?.status?.name === "completed";

  const form = useForm({
    initialValues: {
      status: manualStatus,
      downtime_type: "unplanned",
      note: "",
      ...(isManualType ? { started_at: "", ended_at: "" } : {}),
      work_order_id: woId,
    },
    validationSchema: updateStatusValidationSchema({
      t,
      isManual: isManualType,
      endedAtRequired,
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = getTrackingFormatForService(values, isManualType);
      updateAsset({
        id: asset?.id,
        body,
      })
        .unwrap()
        .catch(({ message }) => {
          addSnackBar({
            title: t("assets.failedUpdate"),
            content: JSON.stringify(message),
            type: "error",
            secondaryButtonLabel: "Close",
          });
        })
        .finally(() => setManualStatus(null));
    },
  });

  useEffect(() => {
    if (data && data.checklist && !checklistValue) {
      setChecklistValue(data.checklist);
    }
  }, [data]);

  useEffect(() => {
    if (isTimesOverdue) setIsConfirmationVisible(true);
  }, [isTimesOverdue]);

  const { isFieldVisible, areVisibleFieldsLoading } =
    useVisibleFields("work_order");

  if (!woId || woId === "calendar") {
    return null;
  }

  if (areVisibleFieldsLoading) return null;

  if (error) {
    return <div>{t("work_orders.detailsError")}</div>;
  }

  if (!data) {
    return null;
  }

  const {
    title: name,
    id,
    description,
    status,
    due_date,
    completed_at,
    priority,
    images = [],
    documents = [],
    updated_at,
    comments,
    checklist,
    asset,
    parts_transactions,
    total_costs,
    technicians,
    created_by_id,
  } = data;

  const files = images
    .map((file) => ({ ...file, isImage: true }))
    .concat(documents);

  const partsTransactions = parts_transactions.map((val) => ({
    ...val,
    part: { ...val.part, label: val.part.name, value: val.part.id },
  }));

  const rows = getViewRows(
    fields,
    data,
    isFieldVisible,
    t,
    displayDate,
    downtimeTrackingEnabled
  );

  if (!downtimeTrackingEnabled) {
    const downtimeKey = Object.keys(rows).find(
      (key) => rows[key].label === "Downtime"
    );
    delete rows[downtimeKey];
  }

  const overdue = isWorkOrderOverdue(due_date, status?.name);
  const isComplete = status?.name === "completed";

  const techniciansIds = technicians.map(({ id }) => id);

  const handleTimeCreate = (values) => {
    const { user, ...others } = values;
    const data = { ...others, user_id: user?.value };
    addTime({ work_order_id: woId, ...data })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          title: t("work_order_time.snack.failCreationTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const handleTimeUpdate = (body, id) => {
    const { user, ...others } = body;
    const data = { ...others, user_id: user?.value };
    updateTime({ id, body: data })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          title: t("work_order_time.snack.failUpdationTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const handleTimeDelete = (id) => {
    deleteTime(id)
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          title: t("work_order_time.snack.failDeletionTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const handleStartTimer = () => {
    startTimer({ work_order_id: woId, user_id: currentUserId })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          title: t("work_order_time.snack.failStartTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const handleStopTimer = ({ rate_type, amount }) => {
    stopTimer({
      work_order_id: woId,
      user_id: currentUserId,
      rate_type,
      amount,
    })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          title: t("work_order_time.snack.failStopTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const handleUpdateChecklist = (value) => {
    setChecklistValue(value);
    updateWorkOrder({
      id: woId,
      body: { checklist: value },
    })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        setChecklistValue(checklist);
        addSnackBar({
          title: t("work_order_time.snack.failUpdateTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const openStopForm = () => {
    setTimerRunning(false);
    setIsShowStopForm(true);
    setIsConfirmationVisible(false);
  };

  const closeStopForm = () => {
    setTimerRunning(true);
    setIsShowStopForm(false);
  };

  const handlePartCreate = ({ part, quantity }) => {
    addPartTransaction({
      id: woId,
      body: { quantity, part_id: part.value },
    })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
        });
      });
  };

  const handlePartUpdate = ({ id, quantity }) => {
    updatePartTransaction({
      id,
      body: { quantity },
    })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
        });
      });
  };

  const handlePartDelete = (id) => {
    deletePartTransaction(id)
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          content: JSON.stringify(message),
          type: "error",
        });
      });
  };

  const handleOtherCostCreate = (values) => {
    const data = getOtherCostFormatForService({
      ...values,
      work_order_id: woId,
    });
    addOtherCost(data)
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          title: t("work_order_other_costs.snack.failCreationTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const handleOtherCostUpdate = (body, id) => {
    const data = getOtherCostFormatForService(body);
    updateOtherCost({ id, body: data })
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          title: t("work_order_other_costs.snack.failUpdationTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const handleOtherCostDelete = (id) => {
    deleteOtherCost(id)
      .unwrap()
      .then(() => {})
      .catch(({ message }) => {
        addSnackBar({
          title: t("work_order_other_costs.snack.failDeletionTitle"),
          content: JSON.stringify(message),
          type: "error",
          secondaryButtonLabel: t("close"),
        });
      });
  };

  const onCloseDowntimeDialog = () => {
    setManualStatus(null);
    form.resetForm();
  };

  let hintContent;
  if (isComplete) {
    hintContent = `: Completed at ${displayDate(completed_at, true)}`;
  } else if (due_date) {
    hintContent = getDueDate(due_date, overdue, isComplete, displayDate);
  }

  return (
    <ViewModal
      isOpen={isShow}
      onClose={onClose}
      label={t("work_orders.name", { name })}
      subHeader={
        <WorkOrderHeader
          workOrder={data}
          id={id}
          name={name}
          onClose={onClose}
          onEdit={onEdit}
          onCompletion={() => fanfareRef.current?.play()}
          status={status}
          createdById={created_by_id}
          technicians={technicians}
        />
      }
      hint={
        <div>
          {overdue && (
            <Overdue>
              <Time />
              {getOverdueDate(displayDate(due_date, true))}
            </Overdue>
          )}
          {isWOActiveTimer && (
            <Timer>
              <TimerIcon />
              <div>{timer}</div>
              <StopTimerButton onClick={openStopForm}>
                {t("work_order_time.stop")}
              </StopTimerButton>
            </Timer>
          )}
        </div>
      }
    >
      <ViewHeader>
        <WorkOrderInfo data-testid="view-modal-hint-heading">
          <Hint>
            #{id} <DueDate>{hintContent}</DueDate>
          </Hint>
          {isFieldVisible("priority") && <Priority value={priority} />}
        </WorkOrderInfo>
        <Name data-testid="view-modal-title">{name}</Name>
        {isFieldVisible("description") && (
          <Description data-testid="view-modal-description">
            {description}
          </Description>
        )}
        <FileManager value={files} />
        <div>
          {assetsStatusesAddDowntimePermit && asset?.id && (
            <Button
              onClick={() => setManualStatus("offline")}
              variant="secondary"
            >
              <div>
                <ThinAddIcon />
                <span> {t("assets.downtime_tracking.manualDowntime")}</span>
              </div>
            </Button>
          )}
          {manualStatus && (
            <DowntimeDialog
              form={form}
              assetCreationDate={asset?.created_at}
              endedAtRequired={endedAtRequired}
              workOrderId={woId}
              workOrderName={name}
              onCancel={onCloseDowntimeDialog}
            />
          )}
        </div>
      </ViewHeader>
      <HeaderDividerStyled />
      <Details date={updated_at} rows={rows} />
      <DividerStyled />
      {woViewChecklistPermit && checklistValue?.length > 0 && (
        <>
          <WorkOrderChecklist
            value={checklistValue}
            onUpdate={handleUpdateChecklist}
            withCheckbox={canUpdateWOChecklist(
              technicians,
              created_by_id,
              status
            )}
          />
          <DividerStyled />
        </>
      )}
      <CostAndTimeContainer>
        {canViewTimeWO() !== false && (
          <WorkOrderTimeList
            onCreate={handleTimeCreate}
            titleFontSize="16px"
            value={timesValue}
            onUpdate={handleTimeUpdate}
            onDelete={handleTimeDelete}
            onStartTimer={handleStartTimer}
            onStopTimer={openStopForm}
            columns={3}
            timer={timer}
            isWOActiveTimer={isWOActiveTimer}
            isUserActiveTimer={isUserActiveTimer}
            techniciansIds={techniciansIds}
            permissions={{
              add: canAddTimeWO(technicians, status),
              delete: canDeleteTimeWO(created_by_id, technicians, status),
              startTimer: canStartTimerWO(technicians, status),
            }}
            status={status}
          />
        )}

        {woPartsViewPermit && (
          <WorkOrderPartList
            onCreate={handlePartCreate}
            onUpdate={handlePartUpdate}
            onDelete={handlePartDelete}
            value={partsTransactions}
            assetId={asset?.id}
            workOrderStatus={status.name}
            titleFontSize="16px"
            permissions={{
              add: canAddPartWO(created_by_id, technicians, status),
              edit: canUpdatePartWO(created_by_id, technicians, status),
              delete: canDeletePartWO(created_by_id, technicians, status),
            }}
          />
        )}
        {woOtherCostViewPermit && (
          <WorkOrderOtherCostsList
            value={otherCosts?.data}
            onCreate={handleOtherCostCreate}
            onUpdate={handleOtherCostUpdate}
            onDelete={handleOtherCostDelete}
            titleFontSize="16px"
            permissions={{
              add: canAddOtherCostWo(created_by_id, technicians, status),
              edit: canEditOtherCostWo(created_by_id, technicians, status),
              delete: canDeleteOtherCostWo(created_by_id, technicians, status),
            }}
          />
        )}
        <WorkOrderTotals value={total_costs} />
      </CostAndTimeContainer>
      <DividerStyled />
      <WOViewTabs comments={comments} histories={histories} targetId={woId} />
      {isConfirmationVisible && (
        <ConfirmationDialog
          cancelButtonLabel={t("cancel")}
          title={t("work_order_time.overdueTimerConfirmation.title")}
          confirmButtonLabel={t(
            "work_order_time.overdueTimerConfirmation.confirm"
          )}
          description={t(
            "work_order_time.overdueTimerConfirmation.description"
          )}
          onProceed={openStopForm}
          onCancel={() => setIsConfirmationVisible(false)}
        />
      )}
      {isShowStopForm && (
        <WorkOrderTimeStop
          timer={timer}
          onSubmit={handleStopTimer}
          onClose={closeStopForm}
        />
      )}
      <Fanfare ref={fanfareRef} />
    </ViewModal>
  );
};
