import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportPageLayout } from "modules/reporting/components/ReportPageLayout";
import { ReportPageTopBar } from "modules/reporting/components/ReportPageTopBar";
import { ReportPageProvider } from "modules/reporting/lib/ReportPageContext";
import { useReportPeriod } from "modules/reporting/lib/useReportPeriod";
import { KPIGrid } from "modules/reporting/components/KPIGrid";
import { useGetChartData } from "modules/reporting/lib/useGetChartData";
import { useGetTableData } from "modules/reporting/lib/useGetTableData";
import {
  PDFReportDownloader,
  PDFReportDownloaderHandle,
} from "modules/reporting/components/PDFReportDownloader/PDFReportDownloader";
import { getReportFilename } from "modules/reporting/lib/getReportFilename";
import { useTranslation } from "react-i18next";
import { ExportMenu } from "modules/reporting/components/ExportMenu";
import { useDownloadReportXLS } from "modules/reporting/lib/useDownloadReportXLS";
import { usePersistentState } from "modules/reporting/lib/usePersistentState";
import { getBucketedChartsList } from "modules/reporting/lib/getBucketedChartsList";
import { useRef } from "react";
import { PartsInventoryChartData, PartsInventoryTableData } from "./types";
import { usePartsInventoryKPIs } from "./usePartsInventoryReportKPIs";
import { PartsInventoryReportChart } from "./PartsInventoryReportChart";
import { MaintenanceByAssigneeChartMode } from "../MaintenanceHistoryByAssignee/MaintenanceHistoryByAssigneeReportChart";
import styled from "styled-components";
import { ToggleButtonGroup } from "../../ToggleButtonGroup";
import { PartsInventoryReportActionBar } from "./PartsInventoryReportActionBar";
import { PartsInventoryReportTable } from "./PartsInventoryReportTable";

const reportKey = ReportKey.PartsInventory;
export const PartsInventoryReport = () => {
  const { t } = useTranslation();
  const [reportPeriod, setReportPeriod] = useReportPeriod();
  const pdfDownloaderRef = useRef<PDFReportDownloaderHandle>(null);
  const kpiRes = usePartsInventoryKPIs(reportPeriod);
  const chartRes = useGetChartData<PartsInventoryChartData>(
    reportKey,
    reportPeriod
  );
  const tableRes = useGetTableData<PartsInventoryTableData[]>(
    reportKey,
    reportPeriod
  );
  const downloadXLS = useDownloadReportXLS(
    ReportKey.PartsInventory,
    reportPeriod
  );

  const [mode, setMode] = usePersistentState("parts", "part_types");
  const isExportReady = !chartRes.isFetching && !kpiRes.isFetching;
  return (
    <ReportPageProvider value={{ reportPeriod, setReportPeriod }}>
      <ReportPageTopBar />
      <ReportPageLayout
        reportKey={reportKey}
        kpis={<KPIGrid isLoading={kpiRes.isFetching} kpis={kpiRes.data} />}
        chart={
          <Wrapper>
            <ModeOptions
              value={mode}
              onChange={(val) => setMode(val as MaintenanceByAssigneeChartMode)}
              ariaLabel={t(`reporting.${ReportKey.PartsInventory}.chart.mode`)}
              options={[
                {
                  label: t(`reporting.${ReportKey.PartsInventory}.chart.parts`),
                  value: "parts",
                },
                {
                  label: t(
                    `reporting.${ReportKey.PartsInventory}.chart.part_types`
                  ),
                  value: "part_types",
                },
              ]}
            />
            <PartsInventoryReportChart
              mode={mode}
              setMode={setMode}
              data={chartRes.data}
              isFetching={chartRes.isFetching}
            />
          </Wrapper>
        }
        actionBar={
          <PartsInventoryReportActionBar
            buttons={
              <ExportMenu
                downloadXLS={() => downloadXLS()}
                downloadPDF={() => pdfDownloaderRef.current?.doDownload()}
                isPDFReady={isExportReady}
              />
            }
          />
        }
        table={
          <PartsInventoryReportTable
            data={tableRes.data?.data}
            paginationData={tableRes.data?.meta.pagination}
            isFetching={tableRes.isFetching}
          />
        }
      />
      <PDFReportDownloader
        ref={pdfDownloaderRef}
        filename={getReportFilename(reportKey, "pdf", reportPeriod)}
        exportProps={{
          reportName: t(`reporting.${reportKey}.title`),
          reportPeriod,
          kpis: kpiRes.data,
        }}
        charts={getBucketedChartsList(t).map((b) => ({
          title: t(`reporting.${reportKey}.title`) as string,
          subtitle: b.label,
          chart: (
            <div style={{ width: "1000px", height: "420px" }}>
              <PartsInventoryReportChart
                mode={mode}
                setMode={setMode}
                data={chartRes.data}
                isFetching={false}
              />
            </div>
          ),
        }))}
      />
    </ReportPageProvider>
  );
};
const ModeOptions = styled(ToggleButtonGroup)`
  position: absolute;
  margin-bottom: 3px;
  transform: translateY(-100%);
  right: 0;
  top: 0;
`;

const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;
