import styled, { css } from "styled-components";
import { Button } from "shared/components/Button";

export const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 40px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  line-height: 14px;
  font-size: 12px;
  color: var(--color_text_color);
  justify-content: center;
  gap: 5px;
  align-items: center;
  display: flex;
  white-space: nowrap;
  border-radius: 0;
  padding: 8px 10px;

  & > svg {
    width: 12px;
    height: 12px;
  }

  ${(props) =>
    props.isSelected &&
    css`
      background: ${`var(--color_${props.color})`};
      border: 2px solid ${`var(--color_${props.borderColor})`};
      cursor: initial;
      pointer-events: none;

      &:hover {
        background: ${`var(--color_${props.color})`};
        border: 2px solid ${`var(--color_${props.borderColor})`};
      }
    `}

  &:first-child {
    border-radius: 4px 0 0 4px;
  }

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
`;

export const ManualDowntimeButton = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StatusContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  margin-top: 20px;
`;

export const ReliabilityHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color_grey100);
`;
