import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LightModal } from "shared/components/Modals/LightModal";
import { Divider } from "shared/components/Divider";
import { Selector } from "shared/components/Selector";
import { H6 } from "shared/components/Typography";
import { IconButton } from "shared/components/IconButton";
import SettingsIcon from "shared/assets/icon/table/settings.svg?react";
import { Columns } from "./Columns";

const options = [
  { key: 25, value: 25 },
  { key: 50, value: 50 },
  { key: 75, value: 75 },
  { key: 100, value: 100 },
];

export const TableSettings = ({
  className,
  columns,
  perPage,
  setTableSettings,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [perPageSelectorValue, setPerPageSelectorValue] = useState(perPage);

  const columnsSettingsInitialValue = useMemo(
    () =>
      columns
        .filter(({ primary }) => !primary)
        .sort(({ order: orderOne }, { order: orderTwo }) => orderOne - orderTwo)
        .map(({ field, name, hidden }) => ({
          field,
          name,
          hidden: hidden ?? false,
        })),
    [columns]
  );

  const [columnsSettings, setColumnsSettings] = useState(
    columnsSettingsInitialValue
  );

  const setFieldVisibility = (field, hidden) => {
    setColumnsSettings((currentSettings) => {
      const index = currentSettings.findIndex(
        (setting) => setting.field === field
      );

      if (typeof index === "number") {
        const settings = [...currentSettings];
        settings[index].hidden = hidden;

        return settings;
      }

      return currentSettings;
    });
  };

  const setFieldOrder = (field, indexToSet) => {
    setColumnsSettings((currentSettings) => {
      const index = currentSettings.findIndex(
        (setting) => setting.field === field
      );
      const filteredCurrentSettings = currentSettings.filter(
        (setting) => setting.field !== field
      );

      if (index !== -1) {
        return [
          ...filteredCurrentSettings.slice(0, indexToSet),
          currentSettings[index],
          ...filteredCurrentSettings.slice(
            indexToSet,
            filteredCurrentSettings.length
          ),
        ];
      }

      return currentSettings;
    });
  };

  const iconClickHandler = () => {
    setIsOpen(true);
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  const applyHandler = () => {
    setTableSettings({
      columnsSettings,
      perPage: perPageSelectorValue,
    });
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        className={className}
        variant="primary"
        onClick={iconClickHandler}
        aria-label="Table Settings"
      >
        <SettingsIcon />
      </IconButton>
      <LightModal
        isOpen={isOpen}
        onClose={closeHandler}
        label={t("table.settings.title")}
        onCancel={closeHandler}
        primaryButtonLabel={t("apply")}
        onSubmit={applyHandler}
      >
        <Box>
          <PerPage>
            <H6>{t("table.settings.resultsPerPage")}</H6>
            <Selector
              options={options}
              selected={perPageSelectorValue}
              onSelect={(key) => setPerPageSelectorValue(key)}
            />
          </PerPage>
          <Divider />
          <Columns
            columnsSettings={columnsSettings}
            setFieldVisibility={setFieldVisibility}
            setFieldOrder={setFieldOrder}
          />
        </Box>
      </LightModal>
    </>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 339px;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
`;

const PerPage = styled.div`
  ${H6} {
    margin-bottom: 16px;
  }
`;
